import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const Page404 = () => {
    return (
        <div className='container'>
            <Result 
                status="404" 
                title="404"
                className='content' 
                subTitle='Sorry, the page you visited does not exist.' 
                extra={<Link to="/" className='ant-btn ant-btn-primary'>Back Home</Link>}/>
        </div>
    );
};

export default Page404;