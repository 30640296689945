import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';

function TestChat116(props) {
    const render_ailist = props.ChatAIList && props.ChatAIList.map(function(ai, index){
        //console.log(ai);
        const disabled = (ai.name!=='Jennifer');
        return (
    		<Button key={index} disabled={disabled} onClick={()=>props.onClickAI(ai.id)}>{ai.name} ({ai.count_history})</Button>
        );
		}
    );


    return (
        <div>
            <h3>TESTING Chat</h3> 

            <Row align='middle'>
                {render_ailist}
            </Row>
        </div>
    )
}

export default TestChat116
