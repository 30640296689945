import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio } from 'antd';
import axios from "axios";
import {USER_SERVER116} from '../../../../config'
const { TextArea } = Input;

function CreateAI(props) {
    const user_id = props.user_id;
    const token = props.token;
    const [category_list, setcategory_list] = useState([]);
    const [Title, setTitle] = useState('');
    const [Visibility, setVisibility] = useState('public');
    const [Category, setCategory] = useState(0);
    const [Gender, setGender] = useState('female');
    const [OnelineDesc, setOnelineDesc] = useState('');
    const [Greeting, setGreeting] = useState('');
    const [Description, setDescription] = useState('');
    const [MyFile, setMyFile] = useState(null);
    const [ImageUrl, setImageUrl] = useState(null);

    useEffect(()=>{
        async function asyncfunc() {
            const res = await axios.post(`${USER_SERVER116}/get_category_list`, {lang:'en_US', for_create:true}).catch(err=>{console.log(err.message); throw err;});
            if (res.data.success) {
                console.log("======;;;;;;", res.data);
                setcategory_list(res.data.data);
            } else {
                alert("Failed to upload file:"+res.data.errmsg);
            }
    
        }
        asyncfunc();
    }, []);

    function onChangeVisibility(e) {
      console.log('radio checked', e.target.value);
      const idx = e.target.value;
      setVisibility(e.target.value);
    };

    function onChangeCategory(e) {
        console.log('radio checked', e.target.value);
        const idx = e.target.value;
        setCategory(e.target.value);

        // if (category_list[e.target.value].id==='ai_boy'){
        //     setGender('male');
        // }
        // else if (category_list[e.target.value].id==='ai_girl'){
        //     setGender('female');
        // }
    };

    function onChangeGender(e) {
        console.log('radio checked', e.target.value);
        const idx = e.target.value;
        setGender(e.target.value);
    };

    async function onClickCreate(){
        let formData = new FormData();
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
    
        formData.append('token', token);
        formData.append('user_id', user_id);
        formData.append('name', Title);
        formData.append('oneline_desc', OnelineDesc);
        formData.append('greeting', Greeting);
        formData.append('description', Description);
        formData.append('visibility', Visibility);
        formData.append('category', category_list[Category].id);
        formData.append('gender', Gender);
        if (ImageUrl) {
            formData.append('image_url', ImageUrl);
        }
        if (MyFile) {
            formData.append("image", MyFile);
        }
    
        // main contents를 upload함
        const res = await axios.post(`${USER_SERVER116}/create_ai`, formData, config).catch(err=>{console.log(err.message); throw err;});
    
        if (res.data.success) {
            console.log("contents upload success:", res.data);
            alert('creation success!')
            props.onBack();
        } else {
            alert("Failed to upload file:"+res.data.errmsg);
        }
    
    }

    // 사용자 pc에서 이미지를 선택할 때
    function onChangeFile(e) {
        setMyFile(e.target.files[0])
        setImageUrl(null);
    }

    // backend의 album에서 image를 선택할 때
    function onClickSelectAlbum(){
        setImageUrl('https://heraigirl.s3.ap-northeast-2.amazonaws.com/ai_album/ive2.jpg');
        setMyFile(null);
        const fileInput = document.getElementById('fileInput');
        fileInput.value='';
    }

    const render_category_list = category_list && category_list.map(function(category, index){
        // console.log(ai);
        return (
            <Radio key={index} value={index}>{category.name}</Radio>
        );
		}
    );

    return (        
        <div>
            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Title:
                </Col>
                <Col span={16}>
                    <Input value={Title} onChange={(e)=>{setTitle(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Image:
                </Col>
                <Col>
                    <Row>
                        <input type='file' id='fileInput' onChange={onChangeFile}/>
                    </Row>
                    <Row>
                        OR
                    </Row>
                    <Row align='middle'>                       
                        <Col style={{marginRight:20}}><Button onClick={onClickSelectAlbum}>Select From Album</Button></Col> 
                        <Col>{ImageUrl}</Col>                        
                    </Row>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Oneline Description:
                </Col>
                <Col span={16}>
                    <TextArea placeholder={"300 characters or less"} rows={4} showCount maxLength={300} onChange={(e)=>{setOnelineDesc(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Greeting:
                </Col>
                <Col span={16}>
                    <TextArea placeholder={"400 characters or less"} rows={4} showCount maxLength={400} onChange={(e)=>{setGreeting(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Visibility:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeVisibility} value={Visibility}>
                    <Radio value='public'>public</Radio>
                    <Radio value='private'>private</Radio>
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Category:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeCategory} value={Category}>
                        {render_category_list}
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Gender:
                </Col>
                <Col span={16}>
                    <Radio.Group onChange={onChangeGender} value={Gender}>
                    <Radio value='male'>male</Radio>
                    <Radio value='female'>female</Radio>
                    </Radio.Group>                
                </Col>
            </Row>

            <Row style={{marginTop:30}}>
                <Col span={4}>
                    Description:
                </Col>
                <Col span={16}>
                    <TextArea placeholder={"2200 characters or less"} rows={8} showCount maxLength={3200} onChange={(e)=>{setDescription(e.currentTarget.value)}}/>
                </Col>
            </Row>

            <Row justify='center' style={{marginTop:30}}>
                <Button type='primary' onClick={props.onBack} style = {{margin:10}}> Back </Button>
                <Button type='primary' onClick={onClickCreate} style = {{margin:10}}> Create </Button>
            </Row>

        </div>
    )
}

export default CreateAI
