import React from "react";
import RightMenu from "./Sections/RightMenu";
import { Row, Col } from "antd";
import LogoNew from "../../../assets/images/icons8-weary-cat-48.png";
import "./Sections/Navbar.css";

function NavBar() {
    return (

        <nav className="menu">
            <div className="menu-inner">
                <div className="container">
                    <Row justify="space-between " className="header-row" align="center">
                        <Col>
                            <a href='/' style={{marginLeft:'40px', fontSize:'20px'}}>
                                <img src={LogoNew} alt="Logo" className="logo"/> 
                            </a>
                        </Col>
                        <Col>
                            <h2>
                                Human Emulation Robot Test
                            </h2>
                        </Col>
                        <Col className="right-menu">
                            <RightMenu mode="horizontal"/>
                        </Col>
                    </Row>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
