// set port of backend
const PORT                = 7001;     // for my private

// base url
const baseUrl = 'http://localhost:7001';                // my private local

//const USER_SERVER   = '/api';
const USER_SERVER   = '/api/v116';
const USER_SERVER116 = '/api/v116';


module.exports = {
    PORT,
    baseUrl,
    USER_SERVER,
    USER_SERVER116
}
