import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, Radio } from 'antd';
import axios from "axios";
import {USER_SERVER116} from '../../../../config'
import EditAINovice from './EditAINovice';
import EditAIExpert from './EditAIExpert';

function MyPage(props) {
    const user_id = props.user_id;
    const token = props.token;
    const { Meta } = Card;
    const [AiList, setAiList] = useState([]);
    const [EditMode, setEditMode] = useState(false);
    const [ExpertMode, setExpertMode] = useState('novice');
    const [CurrAI, setCurrAI] = useState(null);

    useEffect(()=>{
        get_ailist_by_author();
    }, []);

    async function onClickAI(ai){
        setCurrAI(ai);
        setEditMode(true);
    }

    function onChangeExpertMode(e) {
        const idx = e.target.value;
        setExpertMode(e.target.value);
    };

    async function get_ailist_by_author() {
        const res = await axios.post(`${USER_SERVER116}/get_ailist_by_author`, {token,user_id}).catch(err=>{console.log(err.message); throw err;});
        if (res.data.success) {
            console.log("get_ailist_by_author======>", res.data);
            setAiList(res.data.data.list);
        } else {
            alert("Failed to upload file:"+res.data.errmsg);
        }
    }

    async function onClickDeleteAI(e, ai_id){
        e.stopPropagation();
        const res = await axios.post(`${USER_SERVER116}/delete_ai`, {token,user_id, ai_id}).catch(err=>{console.log(err.message); throw err;});
        if (res.data.success) {
            console.log("======>", res.data);
            get_ailist_by_author();
        } else {
            alert("Error:onClickDeleteAI"+res.data.errmsg);
        }
    }

    const render_ai_list = AiList && AiList.map(function(ai, index){
        return (
            <Col span={6} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card cover={<img alt="photo" src={ai.photo_url} />} onClick={()=>onClickAI(ai)} style={{width:200, cursor:'pointer'}}>
                    <Meta title={ai.bold} description={ai.normal}/>
                    <Button disabled={true} onClick={(e)=>onClickDeleteAI(e, ai.id)} style={{marginTop:10}}>Delete</Button>
                </Card>
            </Col>
        );
		}
    );

    async function reload() {
        await get_ailist_by_author();
        setEditMode(false);
    }

    return (        
        <div>
            {EditMode===false?
                <div>
                    <Row style={{marginTop:30, marginBottom:20}}>
                        <Col span={16}>
                        <Radio.Group onChange={onChangeExpertMode} value={ExpertMode}>
                        <Radio value='novice'>Novice Mode</Radio>
                        <Radio value='expert'>Expert Mode(prompt editable)</Radio>
                        </Radio.Group>                
                        </Col>
                    </Row>

                    <Row style={{marginTop:30, marginBottom:20}}>
                        <h2>AI List Created by Me</h2>
                    </Row>

                    <Row justify='space-around'>
                        {render_ai_list}
                    </Row>

                    <Row justify='center'>
                        <Button type='primary' onClick={props.onBack}>Back</Button>
                    </Row>
                </div> :
                ExpertMode==='expert'?
                <div>
                    <EditAIExpert user_id={user_id} token={token} ai={CurrAI} onBack={reload}/>
                </div> :
                <div>
                    <EditAINovice user_id={user_id} token={token} ai={CurrAI} onBack={reload} onReload={reload}/>
                </div> 
        }
        </div>
    )
}

export default MyPage
